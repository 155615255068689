.block-univer{
	background-position: center top;
	background-color: #1f346d;
	background-image: url(/assets/layout/public/img/about/block-univer.jpg);
	padding-top: 45px;
	padding-bottom: 20px;
	z-index: 0;
	margin-bottom: -20px;
}
.block-univer__title{
	color: #fff;
}
.block-univer__title-more{
	color: #fff;
}
.block-univer__video-container{
	text-align: center;
}
.block-univer__video{
	display: inline-block;
	margin-top: 10px;
	color: #fff;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 0.4em;
}
.block-univer__video-text{
	display: block;
	margin-top: 5px;
}
.block-univer__filter{
	position: absolute;
	z-index: 1;
	top: 0;
	height: 100%;
	width: 100%;
	background: inherit;
	background-clip: content-box;
	filter: blur(15px);
	-webkit-filter: blur(15px);
	-moz-filter: blur(15px);
	-ms-filter: blur(15px);
	-o-filter: blur(15px);
	filter: progid:DXImageTransform.Microsoft.Blur(pixelradius=15, enabled='true');
	-ms-filter: "progid:DXImageTransform.Microsoft.Blur(pixelradius=15, enabled='true')";
}
.block-univer__filter-line{
	position: absolute;
	z-index: 2;
	top: 0;
	height: 100%;
	width: 100%;
	background: inherit;
	background-clip: content-box;
	padding-bottom: 279px;
}
.block-univer__container-relative{
	position: relative;
	z-index: 3;
}
.block-univer__triggers{
	padding: 32px 0;
	margin-top: 165px;
	position: relative;
	z-index: 3;
}
.block-univer__trigger{
	color: #fff;
	height: 195px;
	border-right: 1px solid rgba(255,255,255,0.34);
	padding-left: 20px;
	position: relative;
}
.block-univer__trigger_line-disable{
	border: none;
}
.block-univer__trigger-img-container{
	height: 60px;
	position: relative;
}
.block-univer__trigger-img{
	position: absolute;
	bottom: 0;
}
.block-univer__trigger-num{
	font-size: 52px;
	letter-spacing: -0.025em;
	margin-top: 8px;
	position: relative;
	font-weight: 300;
	display: block;
	line-height: 1;
}
.block-univer__trigger-num_symbol:before{
	position: absolute;
	content: '\003E';
	display: block;
	left: -20px;
	font-size: 30px;
	top: 14px;
}
.block-univer__trigger-info{
	font-size: 24px;
}
.block-univer__catalog-img{
	display: block;
	margin-top: -120px;
}
.block-univer__catalog-button{
	color: #fff;
	line-height: 44px;
	font-size: 13px;
	font-weight: 700;
	text-align: center;
	width: 151px;
	background-color: #f73d4f;
	border-radius: 3px;
	box-shadow: 3px 3px 3px rgba(20,20,20,0.34);
	margin-top: 6px;
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.025em;
}

@media (max-width: 1199px){

	.block-univer__trigger-info{
		font-size: 22px;
	}
	.block-univer__trigger{
		padding-left: 0;
	}
	.block-univer__title br{
		display: block;
	}

}
@media (max-width: 991px){

	.block-univer__trigger-info{
		font-size: 24px;
	}
	.block-univer__trigger{
		text-align: center;
		border: none;
		margin-top: 20px;
	}
	.block-univer__trigger-img{
		position: relative;
	}
	.block-univer__trigger-img-container{
		margin-bottom: -10px;
	}
	.block-univer__trigger-num{
		display: inline-block;
	}
	.block-univer__trigger-info{
		display: block;
	}
	.block-univer__filter-line{
		padding-bottom: 600px;
	}
	.block-univer__catalog-img{
		display: inline-block;
		margin-top: 0;
		margin-left: -18px;
	}
	.block-univer__catalog-button{
		display: inline-block;
	}
	.block-univer__trigger_line-disable{
		margin-bottom: 90px;
	}
	.block-univer__trigger_sm-padding-top{
		padding-top: 50px;
	}
}
@media (max-width: 768px){
	
	.block-univer__filter-line{
		padding-bottom: 1040px;
	}
	.block-univer__trigger_sm-padding-top{
		padding-top: 0;
	}

}