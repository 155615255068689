@charset "UTF-8";
.block-univer {
	background-position: center top;
	background-color: #1f346d;
	background-image: url(/assets/layout/public/img/about/block-univer.jpg);
	padding-top: 45px;
	padding-bottom: 20px;
	z-index: 0;
	margin-bottom: -20px;
}

.block-univer__title {
	color: #fff;
}

.block-univer__title-more {
	color: #fff;
}

.block-univer__video-container {
	text-align: center;
}

.block-univer__video {
	display: inline-block;
	margin-top: 10px;
	color: #fff;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 0.4em;
}

.block-univer__video-text {
	display: block;
	margin-top: 5px;
}

.block-univer__filter {
	position: absolute;
	z-index: 1;
	top: 0;
	height: 100%;
	width: 100%;
	background: inherit;
	background-clip: content-box;
	filter: blur(15px);
	-webkit-filter: blur(15px);
	-moz-filter: blur(15px);
	-ms-filter: blur(15px);
	-o-filter: blur(15px);
	filter: progid:DXImageTransform.Microsoft.Blur(pixelradius=15, enabled='true');
	-ms-filter: "progid:DXImageTransform.Microsoft.Blur(pixelradius=15, enabled='true')";
}

.block-univer__filter-line {
	position: absolute;
	z-index: 2;
	top: 0;
	height: 100%;
	width: 100%;
	background: inherit;
	background-clip: content-box;
	padding-bottom: 279px;
}

.block-univer__container-relative {
	position: relative;
	z-index: 3;
}

.block-univer__triggers {
	padding: 32px 0;
	margin-top: 165px;
	position: relative;
	z-index: 3;
}

.block-univer__trigger {
	color: #fff;
	height: 195px;
	border-right: 1px solid rgba(255, 255, 255, 0.34);
	padding-left: 20px;
	position: relative;
}

.block-univer__trigger_line-disable {
	border: none;
}

.block-univer__trigger-img-container {
	height: 60px;
	position: relative;
}

.block-univer__trigger-img {
	position: absolute;
	bottom: 0;
}

.block-univer__trigger-num {
	font-size: 52px;
	letter-spacing: -0.025em;
	margin-top: 8px;
	position: relative;
	font-weight: 300;
	display: block;
	line-height: 1;
}

.block-univer__trigger-num_symbol:before {
	position: absolute;
	content: '\003E';
	display: block;
	left: -20px;
	font-size: 30px;
	top: 14px;
}

.block-univer__trigger-info {
	font-size: 24px;
}

.block-univer__catalog-img {
	display: block;
	margin-top: -120px;
}

.block-univer__catalog-button {
	color: #fff;
	line-height: 44px;
	font-size: 13px;
	font-weight: 700;
	text-align: center;
	width: 151px;
	background-color: #f73d4f;
	border-radius: 3px;
	box-shadow: 3px 3px 3px rgba(20, 20, 20, 0.34);
	margin-top: 6px;
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.025em;
}

@media (max-width: 1199px) {
	.block-univer__trigger-info {
		font-size: 22px;
	}
	.block-univer__trigger {
		padding-left: 0;
	}
	.block-univer__title br {
		display: block;
	}
}

@media (max-width: 991px) {
	.block-univer__trigger-info {
		font-size: 24px;
	}
	.block-univer__trigger {
		text-align: center;
		border: none;
		margin-top: 20px;
	}
	.block-univer__trigger-img {
		position: relative;
	}
	.block-univer__trigger-img-container {
		margin-bottom: -10px;
	}
	.block-univer__trigger-num {
		display: inline-block;
	}
	.block-univer__trigger-info {
		display: block;
	}
	.block-univer__filter-line {
		padding-bottom: 600px;
	}
	.block-univer__catalog-img {
		display: inline-block;
		margin-top: 0;
		margin-left: -18px;
	}
	.block-univer__catalog-button {
		display: inline-block;
	}
	.block-univer__trigger_line-disable {
		margin-bottom: 90px;
	}
	.block-univer__trigger_sm-padding-top {
		padding-top: 50px;
	}
}

@media (max-width: 768px) {
	.block-univer__filter-line {
		padding-bottom: 1040px;
	}
	.block-univer__trigger_sm-padding-top {
		padding-top: 0;
	}
}

.block-top {
	padding: 30px 0 80px;
	/* Без баннера: padding: 55px 0 80px; */
	/* task https://sd.synergy.ru/Task/View/320000
	background-color: #3e3e4c;
	background-image: url(/assets/templates/tv/img/bg/block-top-home.jpg); */
	background-color: #000;
	background-position: center -100px;
}

.block-top__live {
	height: 510px;
	margin-right: 25px;
}

.block-top__title {
	margin-bottom: 8px;
	position: relative;
	max-width: 100%;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 0.4em;
	display: inline-block;
}

.block-top__title_color-red {
	color: #f73d4f;
}

.block-top__title_color-white {
	color: #fff;
}

.block-top__live-video {
	height: 360px;
	/*background-color: #97918e;*/
	/*background-image: url(/assets/templates/tv/img/card-video-photo/1.jpg);*/
	background-size: cover;
	background-position: center;
	overflow: hidden;
}

.block-top__live-video iframe {
	width: 100%;
}

.block-top__schedule-item {
	padding: 33px 8px 8px 19px;
	height: 162px;
	position: relative;
	background-color: #3e3e4c;
}

.block-top__item-progress {
	position: absolute;
	top: 16px;
	height: 3px;
	left: 19px;
	right: 19px;
	background-color: #fff;
}

.block-top__item-progress-elapsed {
	background-color: #5f85ff;
	width: 70%;
	height: 100%;
}

.block-top__schedule-item_active {
	background-color: #184df7;
}

.block-top__schedule-time {
	font-size: 18px;
	color: #8d96a3;
	text-transform: uppercase;
}

.block-top__schedule-item_active .block-top__schedule-time {
	color: #5f85ff;
}

.block-top__schedule-name {
	color: #fff;
	font-size: 18px;
}

.block-top__schedule-speaker {
	font-size: 13px;
	font-weight: 700;
	color: #fff;
	line-height: 20px;
}

.block-top__last {
	height: 510px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.block-top__card-video {
	height: 161px;
	background-color: #eceff7;
	/*cursor: pointer;*/
	position: relative;
}

.block-top__card-video-content {
	width: 54%;
	float: left;
	height: 100%;
	padding: 20px 18px 0;
}

.block-top__card-video-type {
	font-size: 13px;
	text-transform: uppercase;
	color: #8d96a3;
}

.block-top__card-video-speaker {
	font-size: 14px;
	margin-top: 16px;
	font-weight: 700;
	color: #363b42;
}

.block-top__card-video-name {
	font-size: 18px;
	color: #363b42;
}

.block-top__card-video-image {
	width: 46%;
	float: right;
	background-color: #54535a;
	height: 100%;
	position: relative;
	background-size: auto 100%;
	background-position: center;
	background-repeat: no-repeat;
}

.block-top__card-video-play-icon {
	position: absolute;
	width: 30px;
	height: 30px;
	left: 12px;
	bottom: 12px;
	display: block;
	box-shadow: 3px 0 5px rgba(46, 46, 46, 0.46);
	border-radius: 100%;
}

/* banners */
.block-b__link {
	display: block;
	margin-bottom: 30px;
	transition: .2s;
}

.block-b__link,
.block-b__link:hover,
.block-b__link:active,
.block-b__link:focus {
	outline: none;
	text-decoration: none;
}

.block-b__link:hover {
	opacity: .75;
}

.block-b__img {
	max-width: 100%;
	height: auto;
}

@media (max-width: 767px) {
	.block-b__link {
		margin-bottom: 15px;
	}
}

.block-news {
	padding: 45px 0 80px;
	background-color: #eceff7;
}

.block-news__container {
	margin-top: 32px;
}

.block-program {
	padding: 45px 0 50px;
	background-color: #3e3e4c;
	background-image: url(/assets/layout/public/img/common/block-program.jpg);
}

.block-program__title {
	color: #fff;
}

.block-program__title-more {
	color: #fff;
}

.block-program__title2 {
	margin-top: -10px;
	color: #fff;
}

.block-program__slider {
	margin-top: 25px;
}

.block-forums {
	padding: 45px 0 80px;
	background-color: #fff;
}

.block-vebinars {
	padding: 45px 0 80px;
	background-color: #fff;
}

.block-masters {
	padding: 45px 0 80px;
	background-color: #eceff7;
}

.block-seminars {
	padding: 45px 0 80px;
	background-color: #fff;
}

.block-forums {
	padding: 45px 0 80px;
	background-color: #fff;
}

.programm-forum {
	background-color: #fff;
	box-shadow: 0 0 7px rgba(104, 110, 132, 0.5);
	padding-bottom: 42px;
	margin: 32px 0px 30px;
}

.programm-forum__img-container {
	height: 150px;
	overflow: hidden;
	position: relative;
}

.programm-forum__img {
	max-width: 120%;
	min-width: 100%;
	display: block;
}

.programm-forum__link {
	display: block;
	height: 100%;
	width: 100%;
	background: #ccc no-repeat center 0 / cover;
	transition: .2s;
}

.programm-forum__link:hover,
.programm-forum__link:active,
.programm-forum__link:focus {
	outline: none;
	opacity: .75;
}

.programm-forum__bottom {
	padding: 30px 32px 0;
}

.programm-forum__date {
	border-right: 1px solid #8d90a3;
	margin-right: 5px;
	padding-right: 8px;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 12px;
	color: #242338;
	line-height: .75rem;
	display: inline-block;
}

.programm-forum__day {
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.programm-forum__type {
	display: inline-block;
	color: #8d90a3;
	font-size: 13px;
	text-transform: uppercase;
}

.programm-forum__name {
	display: block;
	font-size: 24px;
	line-height: 1em;
	height: 3em;
	overflow: hidden;
	margin-top: 20px;
	margin-bottom: 12px;
	font-weight: 800;
	color: #242338;
	transition: .2s;
}

.programm-forum__name:hover,
.programm-forum__name:active,
.programm-forum__name:focus {
	outline: none;
	color: #d8101e;
}

.programm-forum__button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 0 7px rgba(104, 110, 132, 0.3);
	border: 1px solid #bcbfcd;
	border-radius: 2px;
	background-color: #fff;
	color: #d8101e !important;
	text-align: center;
	padding: 18px;
	max-width: 206px;
	margin-top: 20px;
	line-height: 1em;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
}

.programm-forum__button:hover,
.programm-forum__button:active,
.programm-forum__button:focus {
	border-color: #d8101e !important;
	text-decoration: none;
	opacity: 1;
}

@media (max-width: 1199px) {
	.block-top__live {
		height: auto;
	}
}

@media (max-width: 991px) {
	.block-top__live {
		height: auto;
		margin: 0 0 32px;
	}
	.block-top__last {
		height: auto;
	}
	.block-top__schedule-item {
		height: auto;
		padding-bottom: 12px;
	}
	.block-top__card-video {
		margin-bottom: 12px;
		height: auto;
		padding-bottom: 20px;
	}
	.block-top__card-video-image {
		float: none;
		position: absolute;
		height: 100%;
		right: 0;
	}
	.block-top__last {
		display: block;
	}
}
